import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

@Component({
  selector: 'app-not-granted-block',
  templateUrl: './not-granted-block.component.html',
  styleUrl: './not-granted-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class NotGrantedBlockComponent {}
