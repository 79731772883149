import { TitleCasePipe, UpperCasePipe } from '@angular/common'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormControl, FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

import { TranslateModule } from '@ngx-translate/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { debounceTime, filter, skip, takeUntil, tap } from 'rxjs/operators'

import { DestroyDirective } from '~/core/directives'
import { IForecastSearchPreviewDto } from '~/core/models'
import { SelectSearchPipe } from '~/shared/pipes'

@Component({
  selector: 'app-forecast-search-bar',
  templateUrl: './forecast-search-bar.component.html',
  styleUrl: './forecast-search-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [DestroyDirective],
  imports: [
    FormsModule,
    MatIconModule,
    UpperCasePipe,
    TitleCasePipe,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    SelectSearchPipe,
    MatCheckboxModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
  ],
})
export class ForecastSearchBarComponent implements OnInit {
  @HostListener('document:click', ['$event']) click(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.hideSearchDebounce$.next(null)
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (!this.searchOptions.length) {
      return
    }

    switch (event.key) {
      case 'ArrowDown':
        this.selectNextCompany()
        break
      case 'ArrowUp':
        this.selectPrevCompany()
        break
      case 'Escape':
        this.resetSearchForecast()
        break
    }
  }

  @ViewChild('search', { static: true }) searchInput?: ElementRef<HTMLInputElement>

  @Input() searchOptions: IForecastSearchPreviewDto[] = []
  @Input() inRequest = false
  @Input() inSearch = false
  @Input() isAuth = false
  @Input() allowCurrentFolder = false
  @Input() set inCurrentFolder(inCurrentFolder: boolean) {
    this.searchInCurrentFolder = inCurrentFolder
  }
  @Input() set search(search: string) {
    this.searchText = search
  }

  @Output() searchPreview = new EventEmitter<string>()
  @Output() searchForecasts = new EventEmitter<string>()
  @Output() chooseCompany = new EventEmitter<string>()
  @Output() resetSearch = new EventEmitter()
  @Output() clearSearchOptions = new EventEmitter()
  @Output() changeCurrentFolderOption = new EventEmitter<boolean>()

  searchText = ''
  searchControl = new FormControl('')
  searchInCurrentFolder = true
  selectedCompanyId: number | null = null

  readonly $search = new BehaviorSubject<string>('')
  readonly hideSearchDebounce$ = new Subject()

  private readonly destroy$ = inject(DestroyDirective).destroy$

  constructor(
    private eRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.$search
      .pipe(
        skip(1),
        debounceTime(500),
        tap(v => {
          if (!v) {
            this.searchText = ''
            this.selectedCompanyId = null
            this.resetSearch.emit()
          }
        }),
        filter(v => Boolean(v)),
        takeUntil(this.destroy$)
      )
      .subscribe(value => this.getPreviewForecasts(value))

    // combine document click, on blur(if click outside or choose a company)
    this.hideSearchDebounce$.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(() => this.hideSearch())
  }

  resetSearchForecast(submitEvent = true): void {
    this.selectedCompanyId = null

    if (submitEvent) {
      this.searchText = ''
      this.resetSearch.emit()
      this.searchInCurrentFolder = true
    }
  }

  showForecasts(): void {
    if (!this.isAuth) {
      if (this.searchOptions.length) {
        const company = this.searchOptions[0]
        this.chooseCompany.emit(company.ticker)
      }
    } else {
      const company = this.searchOptions.find(v => v.companyId === this.selectedCompanyId)
      if (company) {
        this.chooseCompany.emit(company.ticker)
      } else if (this.searchText) {
        this.searchForecasts.emit(this.searchText)
      }
    }
  }

  changeCurrentFolderOptions(value: boolean): void {
    this.changeCurrentFolderOption.emit(value)
    this.searchText && this.$search.next(this.searchText)
    this.searchInput?.nativeElement.focus()
    this.showForecasts()
  }

  private getPreviewForecasts(search: string): void {
    this.selectedCompanyId = null
    this.searchText = search.trim()
    this.searchPreview.emit(search)
  }

  private selectNextCompany(): void {
    if (!this.selectedCompanyId) {
      this.selectedCompanyId = this.searchOptions[0].companyId
      return
    }

    const companyIndex = this.searchOptions.findIndex(v => v.companyId === this.selectedCompanyId)

    this.selectedCompanyId =
      companyIndex === -1 || companyIndex === this.searchOptions.length - 1
        ? this.searchOptions[0].companyId
        : this.searchOptions[companyIndex + 1].companyId
  }

  private selectPrevCompany(): void {
    if (!this.selectedCompanyId) {
      this.selectedCompanyId = this.searchOptions[this.searchOptions.length - 1].companyId
      return
    }

    const companyIndex = this.searchOptions.findIndex(v => v.companyId === this.selectedCompanyId)

    this.selectedCompanyId =
      companyIndex === -1 || companyIndex === 0
        ? this.searchOptions[this.searchOptions.length - 1].companyId
        : this.searchOptions[companyIndex - 1].companyId
  }

  private hideSearch(): void {
    if (!this.inRequest && !this.searchOptions.length) {
      this.resetSearchForecast(false)
      this.cdr.detectChanges()
      return
    }
    this.inSearch ? this.clearSearchOptions.emit() : this.resetSearchForecast()
  }
}
