import { AsyncPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, Inject, inject, OnInit, Optional } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { RouterLink, RouterLinkActive } from '@angular/router'

import { AuthService } from '@auth0/auth0-angular'
import { TranslateModule } from '@ngx-translate/core'
import { SvgIconComponent } from 'angular-svg-icon'
import { takeUntil } from 'rxjs/operators'

import { DestroyDirective } from '~/core/directives'
import {
  ISearchForecastFacade,
  IUiFacade,
  IUserFacade,
  SEARCH_FORECAST_FACADE,
  UI_FACADE,
  USER_FACADE,
} from '~/core/features'
import { UserNamePipe } from '~/shared/pipes'
import { ADMIN_ROLE, FORECAST_LIST_PATH } from '~const/index'

import { ForecastSearchBarComponent } from './components'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [DestroyDirective],
  imports: [
    AsyncPipe,
    RouterLink,
    FormsModule,
    UserNamePipe,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    TranslateModule,
    MatButtonModule,
    RouterLinkActive,
    SvgIconComponent,
    MatToolbarModule,
    MatSlideToggleModule,
    ForecastSearchBarComponent,
  ],
})
export class HeaderComponent implements OnInit {
  FORECAST_LIST_PATH = FORECAST_LIST_PATH
  ADMIN_ROLE = ADMIN_ROLE
  showMobileSearch = false
  isDarkTheme = false

  private readonly destroy$ = inject(DestroyDirective).destroy$

  constructor(
    @Optional() public authService: AuthService | null,
    @Inject(UI_FACADE) public uiFacade: IUiFacade,
    @Inject(USER_FACADE) public userFacade: IUserFacade,
    @Inject(SEARCH_FORECAST_FACADE) public searchForecastFacade: ISearchForecastFacade
  ) {}

  ngOnInit(): void {
    this.uiFacade.isDarkTheme$.pipe(takeUntil(this.destroy$)).subscribe(themeMode => (this.isDarkTheme = themeMode))
  }

  // TODO: Refactor navigation store. Reset search, selected forecast, data
  navigateToHome(): void {
    location.href = '/'
  }
}
