import { AsyncPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core'

import { BehaviorSubject } from 'rxjs'

import { IUserFacade, USER_FACADE } from '~/core/features'
import { EGuardEntityName, EGuardSectionName } from '~/core/models'

import { UserAccessBlockComponent } from '../user-access-block/user-access-block.component'

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, UserAccessBlockComponent],
})
export class UserAccessComponent {
  readonly isGranted$ = new BehaviorSubject<boolean>(false)

  @Input() sectionName?: EGuardSectionName | null
  @Input() entityName?: EGuardEntityName | null
  @Input() entityField?: string

  constructor(@Inject(USER_FACADE) public userFacade: IUserFacade) {}
}
