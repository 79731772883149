import { AsyncPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

import { BehaviorSubject } from 'rxjs'

import { EGuardEntityName, EGuardSectionName } from '~/core/models'

@Component({
  selector: 'app-user-access-block',
  templateUrl: './user-access-block.component.html',
  styleUrl: './user-access-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, MatIconModule],
})
export class UserAccessBlockComponent {
  readonly isGranted$ = new BehaviorSubject<boolean>(false)

  private grantedPermissionSectionEntities:
    | {
        [key: string]: {
          [key: string]: {
            [key: string]: boolean
          }
        }
      }
    | null
    | undefined
  private grantedSectionName: EGuardSectionName | null | undefined
  private grantedEntityName: EGuardEntityName | null | undefined
  private grantedEntityField: string | null | undefined

  @Input()
  set permissionSectionEntities(
    permissionSectionEntities:
      | {
          [key: string]: {
            [key: string]: {
              [key: string]: boolean
            }
          }
        }
      | null
      | undefined
  ) {
    this.grantedPermissionSectionEntities = permissionSectionEntities
    this.setGrantedStatus()
  }

  @Input()
  set sectionName(name: EGuardSectionName | null | undefined) {
    this.grantedSectionName = name
    this.setGrantedStatus()
  }

  @Input()
  set entityName(name: EGuardEntityName | null | undefined) {
    this.grantedEntityName = name
    this.setGrantedStatus()
  }

  @Input()
  set entityField(name: string | undefined) {
    this.grantedEntityField = name
    this.setGrantedStatus()
  }

  setGrantedStatus(): void {
    if (
      !this.grantedPermissionSectionEntities ||
      !this.grantedSectionName ||
      !this.grantedEntityName ||
      !this.grantedEntityField
    ) {
      return
    }

    const isGranted = Boolean(
      this.grantedPermissionSectionEntities[this.grantedSectionName][this.grantedEntityName][this.grantedEntityField]
    )

    if (isGranted !== this.isGranted$.value) {
      this.isGranted$.next(isGranted)
    }
  }
}
